













































import {
  Component, Vue,
} from 'vue-property-decorator';

import AllianzDepositRecurringResultViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/allianz-deposit-recurring-result-view-model';

@Component({
  name: 'RecurringDepositResult',
})
export default class RecurringDepositResult extends Vue {
  recurring_deposit_result_view_model = Vue.observable(
    new AllianzDepositRecurringResultViewModel(),
  );

  accept() {
    this.$emit('endProcess');
  }
}

